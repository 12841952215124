<script type="text/javascript">
    import Card from '@/components/UI/Card/Card.vue';
    import CardTitle from '@/components/UI/Card/Title.vue';
    import CardSubTitle from '@/components/UI/Card/SubTitle.vue';
    import CardImage from '@/components/UI/Card/Image.vue';
    import CardImagePlaceholder from '@/components/UI/Card/ImagePlaceholder.vue';
    import CardProfileImage from '@/components/UI/Card/ProfileImage.vue';
    import CardText from '@/components/UI/Card/Text.vue';
    import CardBody from '@/components/UI/Card/Body.vue';
    import CardFooter from '@/components/UI/Card/Footer.vue';

    export default {
        Card,
        CardTitle,
        CardSubTitle,
        CardImage,
        CardProfileImage,
        CardImagePlaceholder,
        CardText,
        CardBody,
        CardFooter
    }
</script>