import DashboardRoutes from '@/router/owners/dashboards.js';
import PropertyRoutes from '@/router/owners/properties/index.js';
import AccountingRoutes from '@/router/owners/accounting.js';
import BookingsRoutes from '@/router/owners/bookings.js';
import MaintenanceRoutes from '@/router/owners/maintenance.js';
import VendorRoutes from '@/router/owners/vendors.js';
import PricingRoutes from '@/router/owners/pricing.js';
import ProfileRoutes from '@/router/owners/profile.js';

export default  [
    ...DashboardRoutes,
    ...PropertyRoutes,
    ...AccountingRoutes,
    ...BookingsRoutes,
    ...MaintenanceRoutes,
    ...VendorRoutes,
    ...PricingRoutes,
    ...ProfileRoutes,
]

