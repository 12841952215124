export default [
    {
        path: '/owners/:propertyId(\\d+)?/vendors/:vendorType(general|utilities|contracts)?',
        alias: ['/owners/properties/:propertyId(\\d+)?/vendors/:vendorType(general|utilities|contracts)?'],

        components: {
            default: () => import(/* webpackChunkName: "owners.vendors" */ '@/views/Owners/Vendors/Index.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/vendors',
            label: 'Vendors & Contracts',
            multiRoute: 'owners.vendors.index',
        }
    },

    {
        path: '/owners/:propertyId(\\d+)/vendors/:vendorId(\\d+)',
        alias: ['/owners/properties/:propertyId(\\d+)/vendors/:vendorId(\\d+)'],

        components: {
            default: () => import(/* webpackChunkName: "owners.vendors" */ '@/views/Owners/Vendors/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "main.menu" */ '@/menus/Main/Main.vue'),
            startMenu: () => import(/* webpackChunkName: "owners.start.menu" */ '@/menus/Start/Owners.vue'),
        },
        meta: {
            group: '/owners/vendors',
            label: 'Vendors & Contracts',
            multiRoute: 'owners.vendors.view',
        }
    },
];
