import PublicCommonRoutes from '@/router/public/common.js';
import PublicServicesRoutes from '@/router/public/services.js';
import PublicPropertiesRoutes from '@/router/public/properties.js';
import PublicOfferRoutes from '@/router/public/offers.js';
import PublicLeadsRoutes from '@/router/public/leads.js';
import PublicCollaborationsRoutes from '@/router/public/collaborations.js';
import PublicExternalRoutes from '@/router/public/external.js';

export default [
    ...PublicCommonRoutes,
    ...PublicServicesRoutes,
    ...PublicPropertiesRoutes,
    ...PublicOfferRoutes,
    ...PublicLeadsRoutes,
    ...PublicCollaborationsRoutes,
    ...PublicExternalRoutes,
];